const items = [
    {
        name:'Acasă',
        route:'/'
    },
    {
        name:'Evenimente',
        route:'/evenimente'
    },
    {
        name:'Despre noi',
        route:'/desprenoi'
    },
    {
        name:'Parteneri',
        route:'/parteneri'
    },
    {
        name:'Contact',
        route:'/contact'
    },
];

export default items;